.search-input-circle {
  border: 1px solid #3182c3;
  font-size: 18px;
  padding: 7px 10px;
  outline: none;
  margin: 10px 2%;
  width: 96%;
  border-radius: 5px;
  color: #3182c3;
}
.search-input-circle:hover {
  border: 1px solid #3182c3;
  font-size: 18px;
  padding: 7px 10px;
}
/* .circle-main {
  background-color: rgba(241, 230, 200, 0.774);
  color: black;
} */
.circle-card-style {
  height: 80px;
  border: 1px solid #3182c3;
  margin: 10px 2%;
  width: 96%;
  padding: 10px;
  position: relative;
  color: #3182c3;
  border-radius: 10px;
}
.user-circle {
  font-size: 35px;
}
.name-circle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.circle-three-dots {
  font-size: 40px;
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
}
.add-circle {
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 15px;
  color: #3182c3;
  cursor: pointer;
}
.add-circle-back {
  font-size: 35px;
  color: #3182c3;
  cursor: pointer;
}
.add-circle-card-style {
  padding: 10px 10px;
  border: 1px solid black;
  margin: 10px 2%;
  width: 96%;
  padding: 10px;
  position: relative;

  border-radius: 5px;
}
.add-circle-icon {
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 13px;
  color: #3182c3;
  cursor: pointer;
}
.remove-circle-icon {
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 13px;
  color: #97313f;
  cursor: pointer;
}
.users-container {
  max-height: 300px;
  overflow-y: scroll;
}

.users-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.users-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(10, 94, 94);
  border-radius: 10px;
}

/* Handle */
.users-container::-webkit-scrollbar-thumb {
  background: rgb(10, 94, 94);
  border-radius: 5px;
}

/* Handle on hover */
.users-container::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 48, 48);
}
.setting-slot {
  background-color: transparent;
  color: rgb(17, 99, 99);
  border-radius: 3px;
  border: 2px solid rgb(17, 99, 99);
  margin-top: 1rem;
  font-size: 13px;
  padding: 0.5rem 3rem;
}
.three-dot-menu-style-chat {
  position: absolute;
  background-color: rgb(90, 90, 90);
  right: -110px;
  bottom: -50px;
}
.three-dot-menu-style-chat > p {
  border: 1px solid black;
  padding: 5px;
  font-size: 14px;
  color: white;
}
.three-dot-menu-style-chat > p:hover {
  border: 1px solid black;
  padding: 5px;
  font-size: 14px;
  color: white;
  background-color: #3182c3;
  cursor: pointer;
}

/* .css-1yvlw4u {
  height: 100%;
} */

.circle-img {
  height: 45px;
  width: 45px;
  border-radius: 100%;
  overflow: hidden;
}
.message {
  padding: 15px 20px 0 15px;
  margin-bottom: 10px;
  margin-left: 30px;
  margin-right: 30px;
  overflow-wrap: break-word;
}

.own-message {
  background-color: #3182c3;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
}
.other-message {
  border: 1px solid #3182c3;
  padding: 10px 20px;
  border-radius: 20px;
}

.username {
  font-size: 12px;
  margin: 0 4px;
  padding: 0 4px;
  display: flex;
  justify-content: space-between;
}

.chat-container {
  position: relative;
  height: 87%;
}

.chat-messages-container {
  overflow: auto;
  height: calc(100% - 50px); /* subtract the height of the input field */
}

.chat-input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 10px;
}

.admin-chat-toggle {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.admin-chat-toggle-p {
  background-color: #319795;
  color: white;
  width: 45%;
  text-align: center;
  padding: 7px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}
.admin-chat-toggle-p-active {
  background-color: #3182c3;
  color: white;
  width: 45%;
  text-align: center;
  padding: 7px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
}