.circle-post {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 30px;
}
.circle-users {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 100vh;
  min-width: 300px;
}
.circle-users h3 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.circle-userName {
  font-size: 18px;
}
.circle-user-card {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 15px;
  border-bottom: 1px solid black;
  padding: 15px;
}
.circle-user-card > img {
  height: 55px;
  width: 60px;
  border-radius: 50%;
}
.circle-user-card p {
  font-weight: bold;
}
.btn-style {
  background-color: #3182ce;
  color: white;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 14px;
}
.btn-style:hover {
  background-color: #3e9ef8;
  color: white;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 14px;
}
.circle-user-card-mobile {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 200px;
  height: 200px;
}
.circle-user-card-mobile .btn-style {
  display: block;
}
.mobile-circle-user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* min-width: 700px; */
  max-width: 100%;
  overflow: scroll;
  padding: 20px;
  /* padding-left: 200px; */
}
.mobile-circle-users {
  display: none;
}
.mobile-user-heading {
  display: none;
}
@media screen and (max-width: 768px) {
  .circle-users {
    display: none;
  }
  .mobile-circle-users {
    display: block;
    width: 100%;
    overflow: scroll;
    margin-bottom: 20px;
  }
  .main-contain-mobile {
    width: 100%;
    /* overflow: scroll; */
  }
  .mobile-user-heading {
    display: block;
    margin-left: 20px;
    font-size: 18px;
    font-weight: bold;
  }
}
