/* Login.css */
.background-wrapper {
    background-color: white;
    background-image: url("../../images/background.png");
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
  }
  
  /* Apply responsive styles for smaller screens */
  @media (max-width: 1300px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 120%;
    }
  }
  
  @media (max-width: 1200px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 130%;
    }
  }
  
  @media (max-width: 1100px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 150%;
    }
  }
  
  @media (max-width: 900px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 200%;
    }
  }
  @media (max-width: 700px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 250%;
    }
  }
  @media (max-width: 600px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 300%;
    }
  }
  @media (max-width: 454px) {
    .background-wrapper {
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
      background-size: 400%;
    }
  }