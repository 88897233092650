.search-input-circle {
  border: 1px solid #3182c3;
  font-size: 18px;
  padding: 7px 10px;
  outline: none;
  margin: 10px 2%;
  width: 96%;
  border-radius: 5px;
  color:#3182c3
}
.search-input-circle:hover {
  border: 1px solid #3182c3;
  font-size: 18px;
  padding: 7px 10px;
}
/* .circle-main {
  background-color: rgba(241, 230, 200, 0.774);
  color: black;
} */
.circle-card-style {
  height: 80px;
  border: 1px solid #3182c3;
  margin: 10px 2%;
  width: 96%;
  padding: 10px;
  position: relative;
  color: #3182c3;
  border-radius: 10px;
}
.user-circle {
  font-size: 35px;
}
.name-circle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.circle-three-dots {
  font-size: 40px;
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
}
.add-circle {
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 15px;
  color: #3182c3;
  cursor: pointer;
}
.add-circle-back {
  font-size: 35px;
  color: #3182c3;
  cursor: pointer;
}
.add-circle-card-style {
  padding: 10px 10px;
  border: 1px solid black;
  margin: 10px 2%;
  width: 96%;
  padding: 10px;
  position: relative;

  border-radius: 5px;
}
.add-circle-icon {
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 13px;
  color: #3182c3;
  cursor: pointer;
}
.remove-circle-icon {
  position: absolute;
  right: 10px;
  font-size: 30px;
  top: 13px;
  color: #97313f;
  cursor: pointer;
}
.users-container {
  max-height: 300px;
  overflow-y: scroll;
}

.users-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.users-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(10, 94, 94);
  border-radius: 10px;
}

/* Handle */
.users-container::-webkit-scrollbar-thumb {
  background: rgb(10, 94, 94);
  border-radius: 5px;
}

/* Handle on hover */
.users-container::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 48, 48);
}
.setting-slot {
  background-color: transparent;
  color: rgb(17, 99, 99);
  border-radius: 3px;
  border: 2px solid rgb(17, 99, 99);
  margin-top: 1rem;
  font-size: 13px;
  padding: 0.5rem 3rem;
}
.three-dot-menu-style{
  position: absolute;
  background-color: rgb(90, 90, 90);
  right: -110px;
  bottom: -80px;

}
.three-dot-menu-style > p{
  border: 1px solid black;
  padding: 5px;
  font-size: 14px;
  color: white;
}
.three-dot-menu-style > p:hover{
  border: 1px solid black;
  padding: 5px;
  font-size: 14px;
  color: white;
  background-color: #3182c3;
  cursor: pointer;
}
.circle-img{
  height: 45px;
  width: 45px;
  border-radius: 100%;
  overflow: hidden;
}

